<template>
  <basic-layout header="Policy">
    <div class="bg-white bg-opacity-90">
      <container>
        <div class="py-20 px-10 container mx-auto">
          <div class="justify-center flex items-center text-2xl md:text-4xl text-green-700 font-semibold">
            <span>LIBRARY RULES AND REGULATIONS <span class="italic font-normal">(from Library Manual)</span></span>
          </div>
          <div id="library-id-card" class=" text-lg md:text-xl font-semibold mt-28 py-16">
            <h3>BORROWER’S CARD/LIBRARY CARD</h3>
            <div class="text-gray-600 text-base sm:text-lg sm:pl-10 py-8">
              <div class="flex grid grid-cols-12 my-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="col-span-1 text-right h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <p  class="col-span-11">Library Cards are issued to new students upon presentation of their registration forms and duly filled up application form and upon payment of P50.00.</p>
              </div>
              <div class="flex grid grid-cols-12 my-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="col-span-1 text-right h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <p  class="col-span-11">Old students must have their library cards validated upon enrolment by presenting to the librarian their enrolment forms.</p>
              </div>
              <div class="flex grid grid-cols-12 my-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="col-span-1 text-right h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <p  class="col-span-11">The validity of the Library Card is only for the current semester or term.</p>
              </div>
              <div class="flex grid grid-cols-12 my-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="col-span-1 text-right h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <p  class="col-span-11">The Library Card is non- transferable. Anybody caught using an ID that is not his own maybe deprived of his/ her privileges for a period specified by the librarian.</p>
              </div>
              <div class="flex grid grid-cols-12 my-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="col-span-1 text-right h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <p  class="col-span-11">Student must always bring his/her Library Card upon entering the library and in order to enjoy borrowing privileges. It should be presented every time he/she borrows or returns a library book.</p>
              </div>
              <div class="flex grid grid-cols-12 my-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="col-span-1 text-right h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <p  class="col-span-11">In case of loss, the student should report immediately to the librarian for proper        replacement upon presentation of affidavit of loss. A student cannot be re-issued another card within an academic year unless he/she presented an affidavit of loss and upon payment of the required amount of P50.00.</p>
              </div>
            </div>
          </div>
          <div id="control-procedure" class="text-xl font-semibold mt-10 py-16">
            <h3>CONTROL PROCEDURE</h3>
            <div class="text-gray-600 text-base sm:text-lg sm:pl-10 py-8">
              <div class="flex grid grid-cols-12 my-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="col-span-1 text-right h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <p  class="col-span-11">All library clienteles/ patrons must present their IDs / LCs upon entering the library.</p>
              </div>
              <div class="flex grid grid-cols-12 my-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="col-span-1 text-right h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <p  class="col-span-11">Students entering the library should be in proper uniform.</p>
              </div>
              <div class="flex grid grid-cols-12 my-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="col-span-1 text-right h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <p  class="col-span-11">Other users must present their ID and request letter from the institution/ agency where they are enrolled / affiliated. They are not allowed to borrow books for home use.</p>
              </div>
              <div class="flex grid grid-cols-12 my-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="col-span-1 text-right h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <p  class="col-span-11">Library users are required to leave their bags, envelops, folders, personal books, and other small things on the depository shelf near the entrance. Small notebooks and other items brought inside the library are subject to inspection..</p>
              </div>
            </div>
          </div>
          <div id="borrowing-rights-and-responsibilities" class="text-xl font-semibold mt-10 py-16">
            <h3>BORROWING RIGHTS AND RESPONSIBILITIES</h3>
            <div class="text-gray-600 text-base sm:text-lg sm:pl-10 py-8">
              <div class="flex grid grid-cols-12 my-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="col-span-1 text-right h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <p  class="col-span-11">Students may borrow only one book at a time.</p>
              </div>
              <div class="flex grid grid-cols-12 my-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="col-span-1 text-right h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <p  class="col-span-11">Faculty members are allowed to borrow one book per subject they teach per semester. The possibility of “Renewal” is subject to eventual request by other users. Books in the reserve section are not lent to faculty members/ employees if these are badly needed by the students.</p>
              </div>
              <div class="flex grid grid-cols-12 my-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="col-span-1 text-right h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <p  class="col-span-11">Books in the General Circulation can be borrowed for one day. Fiction books can be borrowed for one week. However, if there is a great demand or request from other borrowers, the books may be subjected for recall.</p>
              </div>
              <div class="flex grid grid-cols-12 my-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="col-span-1 text-right h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <p  class="col-span-11">General Reference books such as dictionaries, encyclopedias, atlases, manuals, and others, including newspapers, magazines and other journals are to be read inside the library only.</p>
              </div>
              <div class="flex grid grid-cols-12 my-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="col-span-1 text-right h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <p  class="col-span-11">Other researchers or non-members of the MinSCAT community are required to pay fifty pesos (P50.00) as research fee for the use of materials or facilities and present a letter of permission and valid ID. All materials shall be for room use only.</p>
              </div>
              <div class="flex grid grid-cols-12 my-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="col-span-1 text-right h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <p  class="col-span-11">Borrowers are held responsible for all books charged to their names, including mutilation and / or defacement found in library books when returned. Books must be checked and borrowers must report any mutilation found before borrowing.</p>
              </div>
            </div>
          </div>
          <div id="reserve-books-for-overnight-loan" class="text-xl font-semibold mt-10 py-16">
            <h3>RESERVE BOOKS FOR OVERNIGHT LOAN</h3>
            <div class="text-gray-600 text-base sm:text-lg sm:pl-10 py-8">
              <div class="flex grid grid-cols-12 my-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="col-span-1 text-right h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <p  class="col-span-11">A student may reserve a book for overnight use by writing his name, title, and author in the reservation record. He is entitled only for one book reservation a day.</p>
              </div>
              <div class="flex grid grid-cols-12 my-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="col-span-1 text-right h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <p  class="col-span-11">Reserve books may be taken out for overnight use at 3:30 p.m. from Monday to Friday and are due before 8:00 a.m. the following day.</p>
              </div>
            </div>
          </div>
          <div id="fines-and-penalties" class="text-xl font-semibold mt-10 py-16">
            <h3>FINES AND PENALTIES</h3>
            <div class="text-gray-600 text-base sm:text-lg sm:pl-10 py-8">
              <p class="italic">Fines are imposed to all borrowers (students, faculty, and outsiders) for breach of any of the library rules.</p>
              <div class="flex grid grid-cols-12 my-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="col-span-1 text-right h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <p  class="col-span-11">A fine of P 15.00 per day including Sundays and holidays shall be collected for all overdue of General Circulation Materials. </p>
              </div>
              <div class="flex grid grid-cols-12 my-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="col-span-1 text-right h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <p  class="col-span-11">A fine of P 10.00 per hour or P 240.00 per day shall be collected for all Reserve Books kept beyond the due time.</p>
              </div>
              <div class="flex grid grid-cols-12 my-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="col-span-1 text-right h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <p  class="col-span-11">Users who borrow books for photocopying purposes should fill up the photocopying slip and are allowed to keep the books for thirty (30) minutes only, beyond which, a 	fine of ten pesos (P10.00) per hour will be charged.</p>
              </div>
              <div class="flex grid grid-cols-12 my-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="col-span-1 text-right h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <p  class="col-span-11">No permit/ clearance will be signed until fines and other library accountabilities are settled.</p>
              </div>
            </div>
          </div>
          <div id="losses-and-mutilation" class="text-xl font-semibold mt-10 py-16">
            <h3>LOSSES AND MUTILATION</h3>
            <div class="text-gray-600 text-base sm:text-lg sm:pl-10 py-8">
              <div class="flex grid grid-cols-12 my-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="col-span-1 text-right h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <p  class="col-span-11">A lost book must be replaced as soon as possible by the same edition of the same book. </p>
              </div>
              <div class="flex grid grid-cols-12 my-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="col-span-1 text-right h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <p  class="col-span-11">If it cannot be replaced within 30 days, payment shall be equivalent of the books current market value together with the accrued fine. If the cost is out of print, cost of photocopying plus binding cost shall be charged in lieu of current market value. Accrued fines shall also be charged and a processing fee of P 50.00 will be charged.</p>
              </div>
              <div class="flex grid grid-cols-12 my-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="col-span-1 text-right h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <p  class="col-span-11">Stealing, writing on books and other library materials, tearing of pages and all other forms of mutilation are considered as major violations and are strictly prohibited and subject to disciplinary action. Penalties like suspension and forfeiture of the library privileges may be meted out depending on the gravity of offense.</p>
              </div>
              <div class="flex grid grid-cols-12 my-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="col-span-1 text-right h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <p  class="col-span-11"></p>
              </div>
              <div class="flex grid grid-cols-12 my-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="col-span-1 text-right h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <p  class="col-span-11">Sanction for violators</p>
              </div>
              <div class="flex w-full">
                <div class="flex-1 mr-2  text-right">
                  <p>First Offense:</p>
                </div>
                <div class="flex-1">
                  <p>One month suspension of library privileges.</p>
                </div>
              </div>
              <div class="flex w-full">
                <div class="flex-1 mr-2 text-right">
                  <p>Second Offense:</p>
                </div>
                <div class="flex-1">
                  <p>One semester suspension of library privileges.</p>
                </div>
              </div>
              <div class="flex w-full">
                <div class="flex-1 mr-2  text-right">
                  <p>Third Offense:</p>
                </div>
                <div class="flex-1">
                  <p>One year suspension of library privileges.</p>
                </div>
              </div>
            </div>
          </div>
          <div id="visitors" class="text-xl font-semibold mt-10 py-16">
            <h3>VISITORS</h3>
            <div class="text-gray-600 text-base sm:text-lg sm:pl-10 py-8">
              <p class="indent">Researchers outside the College could also avail of the services of the library subject to approval and/or must present a letter of request, and with valid identification card.</p>
              <div class="my-6">
                <h3>Control Procedure</h3>
                <p class="indent">All library clienteles/ patrons must present their IDs / LCs upon entering the library. Other users must present their ID and request letter from the institution/ agency where they are enrolled/affiliated. They are not allowed to borrow books for home use.</p>
              </div>
              <div class="my-6">
                <h3>Borrowing Rights and Responsibilities</h3>
                <p class="indent">Other researchers or non-members of the MinSCAT community are required to pay fifty pesos (P50.00) as research fee for the use of materials or facilities and present a letter of permission and valid ID. All materials shall be for room use only.</p>
              </div>
              <div class="my-6">
                <h3>Fines and Penalties</h3>
                <p class="indent">Fines are imposed to all borrowers (students, faculty, and outsiders) for breach of any of the library rules.</p>
              </div>
            </div>
          </div>
        </div>
      </container>
    </div>
  </basic-layout>
</template>

<script>
import Container from "../layouts/Container";
import BasicLayout from "../layouts/BasicLayout";
export default {
name: "Policy",
  components: {Container, BasicLayout}
}
</script>

<style scoped>
  P{
    @apply ml-2;

  }
  h1{
    @apply text-base sm:text-lg
  }
  h3{
    @apply font-semibold italic text-gray-800
  }
  .indent{
    text-indent: 3rem;
  }
</style>